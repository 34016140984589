//
// Nav Main CSS
//

.nav-main {

  ul {
    @include ul-reset;

    li {
      float: left;
      margin-left: 20px;

      a {
        display: block;
        text-decoration: none;
        color: $grey;
        font-weight: 500;
        line-height: 65px;

        &.active,
        &:hover {
          color: $purple-light;
        }
      }

      &:first-child {
        margin-left: 0;
      }

    }
  }


  @media screen and (max-width: $size-L4) {
    display: none;
  }
}