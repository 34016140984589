//
// Nav Sub CSS
//

.nav-sub {

  @include clearfix;
  padding-left: 180px;

  ul {
    @include ul-reset;
  }

  @media screen and (min-width: $size-L4) {

    display: block !important;

    > ul {

      > li {
        float: left;
        margin-left: 18px;
        position: relative;

        span {
          display: none;
        }

        > a {
          display: block;
          text-decoration: none;
          color: $white;
          font-weight: 500;
          line-height: 65px;

          &.active,
          &:hover {
            //color: $purple-super-light;
            //color: $grey-light;
            color: #cccccc;
          }
        }

        &:first-child {
          margin-left: 0;
        }

      }


      // level 2
      ul {
        display: none;
        position: absolute;
        top: 65px;
        left: 0;
        background: $white;
        z-index: 2;
        width: 240px;
        padding: 15px 20px;
        box-shadow: 2px 2px 5px grey;

        li {

          padding: 2px 0;

          a {
            color: $black;
            text-decoration: none;

            &:hover,
            &.active {
              color: $purple;
            }
          }
        }

      }

      li:hover ul {
        display: block;
      }

    }


  }


  @media screen and (max-width: $size-XL) {
    padding-left: 20px;

    > ul > li {
      margin-left: 16px;
    }
  }


  // mobile version
  @media screen and (max-width: $size-L4) {
    display: none;
    background: $white;
    padding: 0;

    > ul {

      > li {
        border-top: 1px solid $grey-light;
        position: relative;

        span {
          position: absolute;
          top: 7px;
          right: 10px;
          background: url("../Svgs/arrow_thin_purple.svg") no-repeat center center;
          width: 30px;
          height: 30px;
          overflow: hidden;
          text-indent: -99999px;
          cursor: pointer;
          background-size: 16px;
        }

        &.open span {
          -ms-transform:rotate(90deg); /* IE 9 */
          -moz-transform:rotate(90deg); /* Firefox */
          -webkit-transform:rotate(90deg); /* Safari and Chrome */
          -o-transform:rotate(90deg); /* Opera */
        }

        > a {
          display: block;
          padding: 10px 15px;
        }

        > ul {
          display: none;

          padding-left: 30px;

          a {
            display: block;
            padding: 6px 15px;
          }
        }
      }

    }
  }


}