//
// EXT: Powermail CSS
//

.tx-powermail {
  max-width: $maxPageWidthInner2;
  margin: auto;
  padding: 30px 20px;

  .nolabel {

    legend {
      display: none;
    }

  }

}


.powermail_form {
  //max-width: 600px;
}
.powermail_form h3 {
  display: none;
}
.tx-powermail fieldset {
  border: 0;
  margin: 0 0 30px 0;
  padding: 0;
}

.powermail_fieldwrap {
  clear: both;
  padding-top: 20px;
}
.tx-powermail legend {
  //display: none;
  width: 100%;
  @include font-size(32);
  border-bottom: 1px solid $purple-super-light;
}

/*
.tx-powermail .layout2 legend:not([title=""]):after {
  content: " (?)";
  font-weight: normal;
  font-style: italic;
  border-bottom: 1px dotted;
  cursor: pointer;
}
*/

.tx-powermail label {
  float: left;
  width: 160px;
  //@include font-size(20);
  line-height: 36px;
  font-weight: 500;
}
.powermail_fieldwrap textarea,
.powermail_fieldwrap input,
.powermail_fieldwrap input {
  width: 100%;
  border: 1px solid #ccc;
  background-color: #FCFCFC;
  padding: 7px 10px;
}
.powermail_fieldwrap textarea {
  height: 350px;
}
.powermail_fieldwrap select {
  border: 1px solid #ccc;
  background-color: #FCFCFC;
  width: 100%;
  max-width: 320px;
  padding: 7px 10px;
}
.powermail_fieldwrap textarea:focus,
.powermail_fieldwrap input:focus,
.powermail_fieldwrapinput:focus,
.powermail_fieldwrap textarea:hover,
.powermail_fieldwrap input:hover,
.powermail_fieldwrap input:hover {
  background-color: #FFF;
}
.powermail_fieldwrap_date input {
  width: 160px;
}

// checkbox
.powermail_fieldwrap_check  {
  padding-top: 10px;
}
.powermail_check_inner {
  overflow: hidden;
  margin-bottom: 5px;
}
.tx-powermail .powermail_check_legend {
  float: left;
  width: 160px;
  display: block;
  color: $white;
}
.tx-powermail .powermail_fieldwrap_check.layout2 .powermail_check_legend {
  color: $grey;
}
.powermail_check_inner label {
  float: left;
  width: auto;
  padding-top: 0;
}

.powermail_field {
  margin-left: 160px;
}

.powermail_check_inner input  {
  float: left;
  margin-right: 8px;
}

// radio
.powermail_fieldwrap_radio  {
  padding-top: 10px;
}
.tx-powermail .powermail_radio_legend {
  float: left;
  width: 160px;
  display: block;
}

.powermail_radio_outer {
  float: left;
}

.powermail_radio_inner {
  overflow: hidden;
  margin-bottom: 5px;
}
.powermail_radio_inner label {
  float: left;
  max-width: 400px;
  padding-top: 0;
}
.powermail_radio_inner input  {
  float: left;
  margin-right: 8px;
}


.powermail_fieldwrap_submit {
  padding-left: 160px;
  padding-top: 10px;
}

.powermail_fieldwrap input.powermail_submit {
  width: auto;
  display: inline-block;
  border: 1px solid $purple;
  background: $purple;
  border-radius: 3px;
  text-transform: lowercase;
  padding: 5px 10px;
  color: $white;
  cursor: pointer;

  &:hover {
    background: $purple-light;
    border-color: $purple-light;
  }

}

// make text lower case
.tx-powermail {
  select,
  .powermail_file,
  .parsley-errors-list {
    text-transform: lowercase;
  }
}




// form error
div.error {
  background-color: #FFCA4B;
  border: 1px solid #FF8100;
}
.powermail_field_error {
  background-color: #FFCA4B;
  border: 1px solid #FF8100;
}
.formError { z-index: 990; }
.formError .formErrorContent { z-index: 991; }
.formError .formErrorArrow { z-index: 996; position: absolute; left: 15px; }
.formErrorInsideDialog.formError { z-index: 5000; }
.formErrorInsideDialog.formError .formErrorContent { z-index: 5001; }
.formErrorInsideDialog.formError .formErrorArrow { z-index: 5006; }
.inputContainer {
  position: relative;
  float: left;
}
.formError {
  font-family: Calibri, Arial, sans-serif;
  position: absolute;
  top: 300px;
  left: 300px;
  display: block;
  cursor: pointer;
}
.formError .formErrorContent {
  width: 100%;
  position:relative;
  color: #fff;
  width: 150px;
  font-size: 11px;
  border: 2px solid #ddd;
  padding: 4px 10px 4px 10px;
  background: rgb(30,87,153); /* Old browsers */
}
body[dir='rtl'] .formError .formErrorArrow,
body.rtl .formError .formErrorArrow {
  margin: -2px 13px 0 0;
}
.formError .formErrorArrowBottom {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  margin: 0 0 0 12px;
  top:2px;
}
.formError .formErrorArrow div {
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;
  height: 1px;
  margin: 0 auto;
  line-height: 0;
  font-size: 0;
  display: block;
  background: rgb(30,87,153); /* Old browsers */
}
.formError .formErrorArrowBottom div {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.greenPopup .formErrorArrow div {
  background: #33be40;
}
.blackPopup .formErrorArrow div {
  background: #393939;
  color: #FFF;
}
.formError .formErrorArrow .line10 {
  width: 15px;
  border: none;
}
.formError .formErrorArrow .line9 {
  width: 13px;
  border: none;
}
.formError .formErrorArrow .line8 {
  width: 11px;
}
.formError .formErrorArrow .line7 {
  width: 9px;
}
.formError .formErrorArrow .line6 {
  width: 7px;
}
.formError .formErrorArrow .line5 {
  width: 5px;
}
.formError .formErrorArrow .line4 {
  width: 3px;
}
.formError .formErrorArrow .line3 {
  width: 1px;
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;
  border-bottom: 0 solid #ddd;
}
.formError .formErrorArrow .line2 {
  width: 3px;
  border: none;
  background: #ddd;
}
.formError .formErrorArrow .line1 {
  width: 1px;
  border: none;
  background: #ddd;
}

.parsley-errors-list {

  li {
    padding: 0;
    color: red;

    &::before {
      display: none;
    }
  }
}


.powermail_fieldwrap_type_check {

  .powermail_label {
    visibility: hidden;
  }

  .checkbox {
    label {
      font-weight: 300;
      width: 100%;
      line-height: 1;
    }

    input {
      float: left;
      width: 20px;
      margin-right: 5px;
    }
  }

}


.textarea-chars-remaining-note {
  padding: 5px;
  text-align: right;
  font-size: 0.875rem;
}


// hide application header section in initiativbewerbung
.page-id-22 .powermail_fieldset_1 {
  display: none;
}


// loading icon while sending
.application-sending-overlay {
  display: none;
  background: rgba(0, 0, 0, .6);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  img,
  svg {
    display: block;
    @include center;
  }

  &.active {
    display: block;
  }
}



@media screen and (max-width: $size-S4) {

  .tx-powermail label {
    float: none;
  }

  .powermail_fieldwrap_textarea textarea, .powermail_fieldwrap_input input, .powermail_fieldwrap_date input {
    width: 100%;
    margin-left: 0;
  }

  .powermail_field {
    width: 100%;
    margin-left: 0;
  }

  .powermail_check_inner label {
    width: 90%;
  }

  .tx-powermail .powermail_check_legend {
    float: none;
    width: auto;
  }

  .powermail_fieldwrap_submit {
    padding-left: 0;
  }

}
