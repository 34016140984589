//
// Intro Text CSS
//

.intro-text {

  //padding: 30px 0 30px 0;
  background: $purple;
  color: $white;

  h1 {
    opacity: .7;
  }

  p {
  }

}