//
// Open Jobs EXT
//

.tx-openjobs {
  max-width: $maxPageWidthInner2;
  padding: 0 20px;
  margin: auto;
}

// list
.open-jobs {

  .open-jobs-list-headers {
    background: $grey;
    color: $white;
  }

  .open-jobs-list {
    margin-top: 30px;

    ul {
      list-style: none;
      margin: 0 0 30px 0;
      padding: 0;
      border-bottom: 1px solid $grey-light;

      li {

        margin: 0;
        padding: 4px 0;
        border-top: 1px solid $grey-light;
        background: #fbfbfb;
        &:nth-child(odd) {
          //background: #fbfbfb;
        }

        a {
          display: block;
          padding: 5px 0;
          overflow: hidden;
          color: $purple
        }

        &:hover {
          background: $white;
        }

        &:before {
          display: none;
        }

      }

    }


    .open-jobs-title {
      float: left;
      width: 55%;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 500;

      h4 {
        margin: 0;
        font-weight: 500;
        @include font-size(18);
        line-height: 1.4;
      }
    }
    .open-jobs-location {
      float: left;
      width: 20%;
      padding-right: 20px;
      font-weight: 500;
    }
    .open-jobs-function {
      float: left;
      width: 25%;
      padding-right: 20px;
      font-weight: 500;
    }


    .open-jobs-list-headers {
      overflow: hidden;
      padding: 10px 0;

      .open-jobs-title {
        padding-left: 20px;
      }
    }


    @media screen and (max-width: $size-M2) {

      .open-jobs-title {
        width: 70%;
      }

      .open-jobs-function {
        width: 30%;
        padding-right: 0;
      }

      .open-jobs-location {
        float: none;
        clear: left;
        padding-left: 20px;
        width: 100%;
      }

      .open-jobs-list-headers {
        .open-jobs-location {
          display: none;
        }
      }

    }


    @media screen and (max-width: $size-S4) {

      .open-jobs-title {
        width: 100%;
        float: none;
      }

      .open-jobs-function {
        float: none;
        clear: left;
        padding-left: 20px;
        width: 100%;
      }

      .open-jobs-list-headers {
        .open-jobs-function {
          display: none;
        }
      }

    }


  }

}


// Filter

.openjobs-filter {
  padding-top: 10px;
  @include clearfix;

  button.reset {
    float: right;
    border: 1px solid $grey;
    width: 30px;
    height: 30px;
    border-radius: 3px;

    &:hover {
      color: $white;
      background: $purple;
    }
  }

  form {
    @include clearfix;
    margin: 0 40px 0 -10px;

    .openjobs-select {
      float: left;
      width: 25%;
      padding: 0 10px;
    }

    select {
      border: 1px solid $grey;
      width: 100%;
      padding: 4px 8px;
      border-radius: 3px;
    }
  }

  @media screen and (max-width: $size-M) {
    form {

      .openjobs-select {
        width: 50%;
        margin-bottom: 10px;
      }

    }
  }

  @media screen and (max-width: $size-S3) {
    margin-left: 0;
    form {

      .openjobs-select {
        float: left;
        width: 100%;
        //padding: 0;
        margin-bottom: 10px;
      }

    }
  }
}



// Detail

.page-id-30 {

  overflow-x: hidden;

  section.intro-text {
    display: none;
  }
  section.content-main {
    margin-top: 0;
    padding-top: 0;
  }

}


.openjobs-detail {

  .job-images {
    img {
      width: 100vw;
    }
  }

  header {
    background: $purple;
    text-align: center;
    //margin: 0 -1000px 25px -1000px;
    padding: 0 0 15px 0;
    margin-bottom: 25px;

    .header-inner {
      text-align: center;
      max-width: $maxPageWidthInner2;
      margin: auto;
      color: $white;
      opacity: .7;
      padding-top: 10px;
    }

    @media screen and (max-width: $size-L2) {
      margin: 0 -20px 25px -20px;
    }

    @media screen and (min-width: $size-L2) {
      width: 100vw;
      transform: translateX(calc((860px - 100vw)/2));
    }
  }

  h1 {
    margin-bottom: 5px;
    @include font-size(34);
  }

  .job-gender-suffix {
    @include font-size(18);
  }

  .open-jobs-location {
    @include font-size(26);
  }

  h4 {
    @include font-size(22);
    margin: 0 0 10px 0;
    font-weight: 400;
    color: $purple;
  }

  .job-short-description,
  .job-description,
  .job-requirements,
  .job-benefits {
    margin-bottom: 30px;
  }

  .job-benefits-set {
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    margin: -5px;

    li {
      width: calc(100% / 6);
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
      text-align: center;

      &::before {
        display: none;
      }

      @media screen and (max-width: $size-L2) {
        width: calc(100% / 4);
      }

      @media screen and (max-width: $size-M) {
        width: calc(100% / 3);
      }

      @media screen and (max-width: $size-S) {
        width: calc(100% / 2);
      }
    }

    .benefit-label {
      @include font-size(12);
      font-weight: 500;
    }
  }

  .job-benefits-benefit {
    @include font-size(12);
    font-weight: 500;
    background: lightblue;
    background: url("../Images/job_benefits/inhabergerfuehrtes_unternehmen-01.svg") no-repeat;
    background-size: calc(100% - 10px);
    padding-top: 12%;

    @media screen and (max-width: $size-L2) {
      padding-top: 17%;
    }

    @media screen and (max-width: $size-M) {
      padding-top: 23%;
    }

    @media screen and (max-width: $size-S) {
      padding-top: 34%;
    }


    &.family-culture {
      background-size: 60%;
      background-position: top center;
    }
  }

  .job-media {
    margin-top: 20px;

    ul {
      padding: 0;
      display: flex;
      flex-flow: row wrap;
      margin: -10px;
    }

    li {
      width: calc(100% / 3);
      padding: 10px;

      &::before {
        display: none;
      }

      @media screen and (max-width: $size-S5) {
        width: calc(100% / 2);
      }

      @media screen and (max-width: $size-S) {
        width: 100%;
      }
    }

    img {
      object-fit: cover;
      aspect-ratio: 16 / 9;
    }
  }



  .job-contact-name {
    @include font-size(24);
    font-weight: 500;
    margin: 15px 0 20px 0;
  }

  .clearer {
    clear: both;
  }

  .back-link {
    border-top: 1px solid $grey;
    margin-top: 50px;
    padding: 20px 0 50px 0;

    a {
      display: inline-block;
      color: $purple;
      padding: 3px 8px;
    }
  }

}

.job-detail-main {
  padding-bottom: 30px;

  ul {
    padding-left: 20px;

    li {
    }

  }

}


.job-detail-sidebar {

  .job-contact {
    margin-bottom: 20px;

    a {
      color: $purple;
      text-decoration: none;
    }
  }

  h2 {
    margin-top: 0;
    line-height: 1;
  }

  .job-apply-btn {
    margin-top: 20px;

    a {
      display: inline-block;
      background: $purple;
      color: $white;
      text-decoration: none;
      padding: 7px 15px;
      @include font-size(20);
    }
  }

}
