//
// Grid CSS
//

.flex-row {
  margin: -20px;
  overflow: hidden;

  .col-50 {
    margin: 20px 0;
  }


  @media screen and (min-width: $size-M) {
    display: flex;
    flex-flow: row wrap;

    .col-50 {
      width: 50%;
      margin: 0;
      padding: 20px;
    }

  }

}