//
// Header CSS
//

.page > header {

  .header-top {
    background: $black;
    height: 65px;

    .logo {
      float: left;
      margin: 16px 0 0 0;
      width: 180px;

      @media screen and (max-width: $size-XL) {
        width: 100px;
      }
    }

  }


  .header-mid {
    background: $grey; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(#7d7d7d, #505050); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#7d7d7d, #505050); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#7d7d7d, #505050); /* For Firefox 3.6 to 15 */
    background: linear-gradient(#7d7d7d, #505050); /* Standard syntax */


    @media screen and (max-width: $size-L4) {
      position: absolute;
      right: 0;
      top: 65px;
      width: 300px;
      background: $white;
      z-index: 2;

      .inner {
        padding: 0;
      }
    }
  }



}