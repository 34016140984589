//
// Footer CSS
//

.page > footer {

  background: $grey-dark;
  padding: 30px 0 30px 0;
  text-align: center;
  @include font-size(16);
  color: $grey;
  font-weight: 400;

  .inner {
    max-width: $maxPageWidthInner2;
  }

  address {
    font-style: normal;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, .1);

    a {
      color: $grey;
    }
  }

  .footer-links {
    padding-top: 5px;
  }

  .footer-top {
    overflow: hidden;
  }

  .footer-contact,
  .footer-application {
    float: left;
    width: 50%;
    padding:  0 20px;
    text-align: center;
    @include font-size(22);

    img {
      max-height: 100px;
    }

    span {
      display: block;
    }

  }

  .footer-contact img {
    max-width: 106px;
  }

  .footer-application img {
    max-width: 94px;
  }


}