//
// Special for Trainees CSS
//

.page-id-17,
.pid-17 {

  .ce-text-media-custom {

    h3 {
      margin-top: 15px;
      margin-bottom: 25px;
      color: $purple;
    }

    p a {
      color: $grey-dark;
    }

    p {
      margin-bottom: 0;
    }

    p:first-of-type {
      margin-bottom: 20px;
    }

    p:last-of-type {
      margin-top: 10px;
    }

  }

  h5 {
    margin-top: 30px;

    &:first-of-type {
      margin-top: 0;
    }
  }

}

/*
#c110,
#c190 {
  h3 {
    margin-top: 15px;
    margin-bottom: 25px;
    color: $purple;
  }
  h4 {
    color: $grey-dark;
    @include font-size(18);
    a {
      color: $grey-dark;
    }
    line-height: 1.3;

    &:first-of-type {
      margin-bottom: 20px;
    }
  }
}
#c210 {
  h4 {
    @include font-size(18);
    margin-bottom: 15px;
    margin-top: 30px;
  }
}
*/