//
// Mobile Menu CSS
//

.burger-icon {
  float: right;
  width: 30px;
  margin: 20px 0 0 30px;
  cursor: pointer;
  display: none;
  border: none;
  padding: 0;
  background: none;

  div {
    background: $white;
    height: 4px;
    margin: 4px 0;
  }

  @media screen and (max-width: $size-L4) {
    display: block;
  }

}