//
// CE Iamge Grid 3 CSS
//

.ce-image-grid3 {

  .image-container {
    overflow: hidden;
    margin: 0 -15px;
  }

  figure {
    float: left;
    width: 33.333333333%;
    padding: 0 15px;
    text-align: center;
  }

  figcaption {
    text-align: center;
    padding: 0 10px;
  }

  @media screen and (max-width: $size-S3) {
    margin: 0;

    figure {
      float: none;
      width: 100%;
      margin-top: 25px;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }

}