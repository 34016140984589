//
// News CSS
//

.news {

  max-width: $maxPageWidthInner2;
  padding: 0 20px;
  margin: auto;

}


// list view
.news-list-view {

  @include clearfix;

  .page-navigation {

    clear: both;
    padding: 20px 0;

  }

  .article {
    position: relative;
    padding: 15px;
    @include clearfix;
    background: $grey-super-light;
    margin-bottom: 15px;
  }

  .news-img-wrap {
    float: left;
  }

  .news-list-date {

  }

  h3 {
    color: $purple;
    @include font-size(22);
    font-weight: 500;
  }

  .more-link {

    &:hover {

    }
  }

  .news-list-text {
    margin-left: 300px;
  }

  .news-list-date {
    @include font-size(14);
  }

  a.more {
    display: block;
    text-align: right;
    text-transform: lowercase;
    font-weight: 500;
    background: url("../Svgs/arrow_thin_purple.svg") no-repeat right center;
    background-size: 15px;
    padding-right: 20px;
    margin-top: 10px;
  }


  @media screen and (max-width: $size-L2) {
    .news-list-text {
      margin-left: 260px;
    }
  }

  @media screen and (max-width: $size-S6) {

    .news-img-wrap {
      float: none;
      margin-bottom: 20px;
    }

    .news-list-text {
      margin-left: 0;
    }

  }

}

// pagination
.f3-widget-paginator {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  > * {
    display: block;
    margin-right: 16px;
  }
}



// News Detail

.page-id-36 {

  overflow-x: hidden;

  section.intro-text {
    display: none;
  }
  section.content-main {
    margin-top: 0;
    padding-top: 0;
  }

}

.news-single {

  header {
    background: $purple;
    text-align: center;
    margin: 0 -1000px 25px -1000px;
    padding: 30px 0;

    .header-inner {
      text-align: center;
      max-width: $maxPageWidthInner2;
      margin: auto;
      color: $white;
      opacity: .7;
    }

    @media screen and (max-width: $size-L2) {
      margin: 0 -20px 25px -20px;
    }
  }

  h1 {
    margin-bottom: 20px;
  }

  .news-detail-main {
    display: flex;
    gap: 30px;
    flex-flow: column;
  }

  .news-img-wrap {

    .outer {
      margin-bottom: 15px;
    }
  }

  .news-backlink-wrap {
    margin: 15px 0 20px 0;
    text-transform: lowercase;
  }

  .social {
    padding-bottom: 15px;

    .share {
      margin-bottom: 10px;
    }

  }


  @media screen and (min-width: $size-S5) {
    .news-detail-main {
      flex-flow: row;
    }
    .news-img-wrap {
      width: 30%;
      order: 2;
    }
    .news-text-wrap {
      width: 70%;
    }
  }

}
