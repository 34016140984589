//
// Project Color Definitions
//

$purple: #605497;
$purple-light: #7f56c6;
$purple-super-light: #ccc8de;

$grey: #999999;
$grey2: #989ea5;
$grey-light: #e1e3e4;
$grey-super-light: #efefef;
$grey-dark: #353d4d;

$black: #000000;
$white: #FFFFFF;