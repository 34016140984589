//
// CE Text Media Custom CSS
//

.ce-text-media-custom {

  .ce-inner {
    overflow: hidden;
  }

  .media-container {
    float: left;
    width: 33.33333333%;
  }

  figcaption,
  .video-caption {
    padding: 0 10px 0 10px;
    @include font-size(12);
  }


  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* ratio 16x9 */
    height: 0;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  .bodytext {
    float: left;
    width: 66.666666667%;
    padding-left: 30px;
  }

  @media screen and (max-width: $size-S5) {
    .media-container {
      float: none;
      width: auto;
    }

    .bodytext {
      float: none;
      width: auto;
      padding-left: 0;
      padding-right: 0;
    }

    figcaption,
    .video-caption {
      text-align: left;
    }
  }


  // Image orientation right
  &.image-right {

    .media-container {
      float: left;
      width: 33.33333333%;
    }

    .bodytext {
      float: left;
      width: 66.666666667%;
      padding-left: 0;
      padding-right: 30px;
    }

    figcaption,
    .video-caption {
      text-align: right;
    }

    @media screen and (max-width: $size-S5) {
      .media-container {
        float: none;
        width: auto;
      }

      .bodytext {
        float: none;
        width: auto;
        padding-left: 0;
        padding-right: 0;
      }

      figcaption,
      .video-caption {
        text-align: left;
      }
    }

  }

}