//
// My Name is Polaroid image effect CSS
//

.page-id-17,
.pid-17 {

  .content-main {

    figure {
      background: $white;
      padding: 25px 25px 20px 25px;

      img {
        border-radius: 10px;
      }

      figcaption {
        @include font-size(18);
        font-weight: 500;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

  }

}