//
// CE Text Simple CSS
//

.ce-text-simple {
  padding: 0;

  h1 {
    margin-bottom: 0;
  }
}