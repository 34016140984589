//
// CE Custom Media CSS
//

.ce-custom-media {

  figcaption,
  .video-caption {
    text-align: center;
    padding: 0 10px;
    @include font-size(12);
  }

}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16x9 */
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}