//
// Main CSS
//

main {

  background: $grey-light;
  padding-bottom: 15px;

  p {
    margin: 0 0 1em 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    @include ul-reset;
    padding-left: 15px;
    li {
      margin-top: 3px;
      position: relative;
      padding-left: 15px;
      &:before {
        content: "■";
        color: $purple;
        position: absolute;
        left: -15px;
        top: 1px;
        width: 15px;
        @include font-size(15);
      }
    }
  }

  .main-inner {

    //max-width: $maxPageInnerWidth;
    margin: auto;
    padding-top: 25px;
    padding-bottom: 40px;
    overflow: hidden;

  }


  .content-main {

    min-height: 200px;
    padding-top: 20px;

  }

  .ce-inner {
    max-width: $maxPageWidthInner2;
    margin: auto;
    padding: 30px 20px;
  }

  .intro-text {

    .ce-inner {
      padding: 10px 20px;
    }
  }


  &.two-cols {

    @include clearfix;

    .col-left {
      float: left;
      width: 70%;
      padding-right: 40px;
    }

    .col-right {
      float: left;
      width: 30%;
    }

  }

}