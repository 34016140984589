//
// Accordion CSS
//

.accordion {

  background: $white;
  margin-bottom: 1px;

  .ce-inner {
    max-width: none;
    padding: 0;
  }

  header {
    background: $grey;
  }

  h3 {
    position: relative;
    max-width: $maxPageWidthInner2;
    padding: 0 20px 0 50px;
    margin: auto;
    @include font-size(24);
    font-weight: 400;
    line-height: 1.5;
    color: rgba(255, 255, 255, .8);
    cursor: pointer;

    &::before {
      content: "+";
      position: absolute;
      left: 20px;
      top: -4px;
      width: 20px;
      @include font-size(40);
      line-height: 1;
      color: $white;
    }
  }

  .bodytext {
    display: none;
    padding: 20px 50px;
    max-width: $maxPageWidthInner2;
    margin: auto;
  }


  .open {

    h3 {

      &::before {
        content: "–";
        top: -7px;
      }
    }

  }

}