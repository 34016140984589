//
// CE Hero Image CSS
//

.hero-image-container {
  position: relative;

  img {
    display: block;
    margin: auto;
  }

  .figcaption-wrap {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;

      figcaption {
        display: inline-block;
        width: 100%;
        max-width: $maxPageWidthInner2;
        text-align: left;
        color: $white;
        @include font-size(54);
        font-weight: 400;
        line-height: 1.2;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
        padding: 0 20px;
      }
  }

}