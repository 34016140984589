//
// Defaul Project Settings
//

$maxPageWidthInner: 1220px;
$maxPageWidthInner2: 900px;
//$baseFont: 'Open Sans', sans-serif;
$baseFont: 'Quicksand', sans-serif;


//breakpoints
$size-XXXL: 1460px;
$size-XXL: 1300px;
$size-XL: 1200px;
$size-L4: 1020px;
$size-L2: 860px;
$size-L: 800px;
$size-M2: 750px;
$size-M: 700px;
$size-S6: 600px;
$size-S5: 600px;
$size-S4: 530px;
$size-S3: 480px;
$size-S: 430px;
$size-XS: 400px;
$size-XXS: 380px;
$size-XXXS: 360px;


// in use: XL, L4, L2, M2, M, S6, S5, S4, S3, XXXS,