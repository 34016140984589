//
// Import Base CSS and add custom
//

// Base Settings
@import "base/settings";
@import "base/colors";

// Mixins
@import "base/mixins";

// Normalize
@import "base/normalize";

// Add global CSS
@import "base/global";

// Add Magnific Popup
@import "lib/magnific_popup/main";

@import "lib/slick_slider/base";


//
// CUSTOM CSS
//

// general
body {
  font-family: $baseFont;
  @include font-size(18);
  font-weight: 300;
  background: $white;
  color: $black;
  position: relative;
}

h1 {
  margin: 0 0 15px 0;
  @include font-size(54);
  font-weight: 400;
}

h2 {
  margin: 0 0 10px 0;
  @include font-size(50);
  font-weight: 300;
  color: $purple;
}

h3 {
  margin: 0 0 5px 0;
  @include font-size(30);
  font-weight: 300;
  line-height: 1;
}

h4 {
  margin: 0 0 5px 0;
  @include font-size(24);
  font-weight: 300;
  line-height: 1;
  color: $purple;
}

h5 {
  @include font-size(18);
  color: $purple;
  margin: 30px 0 15px 0;
  font-weight: 300;
}


a {
  color: $purple;
  text-decoration: none;
}

.inner {
  max-width: $maxPageWidthInner;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.inner2 {
  max-width: $maxPageWidthInner2;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}


@media screen and (max-width: $size-S5) {
  h1 {
    @include font-size(36);
  }

  h2 {
    @include font-size(36);
  }
}

@media screen and (max-width: $size-XXXS) {
  h1 {
    @include font-size(30);
  }

  h2 {
    @include font-size(30);
  }

  h3 {
    @include font-size(26);
  }
}


@import "header";
@import "header-image";
@import "nav-language";
@import "nav-main";
@import "nav-sub";
@import "burger-icon";
@import "lib/slick_slider/base";

@import "main";
@import "intro-text";

@import "footer";
@import "nav-footer";

@import "grid";

@import "ce-hero-image";
@import "ce-text-simple";
@import "ce-media-custom";
@import "ce-text-media-custom";
@import "ce-image-grid3";
@import "accordion";

@import "news";
@import "open-jobs";
@import "powermail";

@import "trainees";
@import "my-name-is-polaroid";
