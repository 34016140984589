//
// Nav Language CSS
//

.nav-language {
  float: right;

  ul {
    @include ul-reset;

    li {
      float: left;
      margin-left: 20px;

      a {
        display: block;
        text-decoration: none;
        color: $grey;
        font-weight: 400;
        line-height: 65px;

        &.active,
        &:hover {
          color: $purple-light;
        }
      }

      &:first-child {
        margin-left: 0;
      }

    }
  }

  @media screen and (max-width: $size-XXXS) {
    ul li a.active {
      display: none;
    }
  }
}