//
// Nav Footer CSS
//

.nav-footer {
  padding-top: 5px;

  a {
    color: $grey;
    text-decoration: none;
    padding: 0 8px;
  }

}